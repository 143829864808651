.wrapper {
    background-color: var(--bg-ctnt);
}

.Home {
    max-width: 1080px;
    margin: 0 auto;
}

.homeLogo {
    max-width: 90vw;
    width: 316px;
}

.Home h3 {
    margin-left: 42px;
    margin-top: 35px;
    font-size: 17px;
}

.Home a {
    text-decoration: none;
    color: white;
}

.Home ul {
    padding: 0 42px;
    margin-top: 25px;
    margin-bottom: 50px;
}

.Home ul li {
    padding: 16px 25px;
    background-color: var(--bg-cthl);
    border-radius: 5px;
    max-width: 1250px;
}

.Home ul li h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 3px;
}

.Home ul li h2 {
    font-style: italic;
    margin-bottom: 14px;
}