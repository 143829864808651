.sidepanel {
    width: calc(100% - 50px);
    height: 100%;

    padding: 0 25px;
    background-color: var(--bg-side);

    position: relative;
}

.sidepanel .logo {
    width: 97%;
    max-width: 324px;
}

.sidepanel p {
    line-height: 18px;
    margin-bottom: 15px;
}

.streamDetails {
    width: 94%;

    margin-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
}

.streamTitle {
    margin-top: 22px;
    font-size: 18px;
    font-weight: bold;
}

.streamLocation {
    margin-top: 4px;
    font-size: 15px;
    border-bottom: 1px solid #a4a4a473;
    padding-bottom: 10px;
}

.sidepanel .legal {
    position: absolute;
    bottom: 24px;

    width: 82%;

    margin-left: 10px;
    margin-right: 10px;
    padding-top: 17px;

    border-top: 1px solid var(--cl-mtxt);
}


@media only screen and (max-width: 1150px) {
    .sidepanel .legal {
        position: initial;
    }

    .sidepanel {
        height: unset;
        min-height: 100vh;
    }
}



.sidepanel #small {
    display: none;
}


.sidepanel .icon {
    width: 100%;
}




.sidepanel ul li {
    margin: 5px 0;
    color: var(--cl-mtxt)
}

.sidepanel a {
    text-decoration: none;
    color: var(--cl-mtxt);
}