.GDPRStreamFrame.waiting {
    width: 70%;
    min-height: 430px;
    margin: 10vh 15%;
    overflow: auto;
    background-color: var(--bg-head);
    border-radius: 10px;
}

.GDPRStreamFrame.waiting img {
    height: 112px;
    position: absolute;
    right: calc(15% + 13px);
}

.GDPRStreamFrame.waiting h2 {
    margin-left: 48px;
    margin-top: 35px;
    font-size: 26px;
}

.GDPRStreamFrame.waiting p {
    width: 80%;
    margin-left: 48px;
    text-align: left;
}

.GDPRStreamFrame.waiting button {
    padding: 12px 30px;
    background-color: #db2727;
    border: none;
    color: var(--cl-htxt);
    border-radius: 6px;
    margin-left: 48px;
    margin-top: 63px;
    margin-bottom: 28px;
}


@media only screen and (max-width: 740px) {
    .GDPRStreamFrame.waiting img {
        height: 90px;
    }

    .GDPRStreamFrame.waiting h2 {
        margin-top: 31px;
        margin-left: 36px;
    }

    .GDPRStreamFrame.waiting p {
        margin-left: 36px;
    }

    .GDPRStreamFrame.waiting button {
        margin-left: 36px;
    }

}


@media only screen and (max-width: 670px) {
    .GDPRStreamFrame.waiting {
        width: 90%;
        margin: 10vh 5%;
    }

    .GDPRStreamFrame.waiting img {
        right: calc(5% + 13px);
    }

    .GDPRStreamFrame.waiting p {
        margin-left: 36px;
        margin-right: 36px;
        width: unset;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 550px) {
    .GDPRStreamFrame.waiting img {
        display: none;
    }
}