.wrapper {
    width: 100vw;
    height: 100vh;
}

.wrapper:nth-child(n) {
    display: inline-block;
}

.left {
    position: absolute;
    top: 0;
    left: 0;
    width: 384px;
    height: 100%;
    opacity: 1;

    transition: all .3s ease-out;
}

.right {
    position: absolute;
    top: 0;
    left: 384px;
    width: calc(100% - 384px);
    height: 100vh;
    overflow-y: hidden;
    z-index: 900;
}

.fmb {
    display: none;
}

@media only screen and (max-width: 1150px) {
    .right, .left {
        top: 0;
        left: 0;
        width: 100vw;
        min-height: 100vh
    }

    .right {
        overflow-y: auto;
    }

    .left {
        opacity: 0;
        z-index: 800;
        min-height: 460px;
        overflow-y: auto;
        height: unset;
        display: none;
    }

    .left .logo {
        margin-left: -25px;
    }

    .fmb {
        position: absolute;
        top: 25px;
        right: 30px;
        z-index: 1001;
        display: block;
        height: 30px;
        width: 30px;
        cursor: pointer;
        background-color: var(--bg-cthl);
        border-radius: 3px;
    }
}

.show {
    z-index: 1000;
    opacity: 1;
    display: block;
}