.wrapper {
    width: calc(100vw - 84px);
    height: calc(100vh - 48px);
    padding: 24px 42px;
    overflow-y: auto;
}


h1 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
}

h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 24px;
}

h3 {
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 20px;
}

p, strong {
    margin-bottom: 9px;
    line-height: 19px;
}

strong {
    font-weight: bold;
}

a {
    color: #db2727;
    text-decoration: none;
}