.streamView {
    width: 100%;
    min-height: 100vh;

    background-color: var(--bg-cthl);
}

.stream {
    width: 100%;
    height: calc(100vh - 2px);
}

.streamView.waiting{

}

.streamView h1 {
    font-size: 40px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    color: var(--cl-htxt);
    margin-top: 120px;
    margin-bottom: 30px;

    max-width: 87vw;
    margin-left: auto;
    margin-right: auto;
}

.streamView p {
    font-size: 17px;
    width: 45%;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    line-height: 22px;
}

.waiting p {
    margin-top: 64px;
}

.waiting ul {
    background-color: var(--bg-head);
    width: 485px;
    max-width: 80vw;
    padding: 18px 22px;
    border-radius: 14px;
    margin-left: auto;
    margin-right: auto;
}

.waiting li {
    display: inline-block;
    list-style-type: none;
    font-size: 20px;
    text-align: center;
    width: 118px;
}

.waiting li span {
    display: block;
    font-size: 36px;
    color: var(--cl-htxt);
    margin-bottom: 4px;
}


.ended, .techdiff, .notFound {
    text-align: center;
}

.ended .requestRecording {
    width: 240px;
    height: 20px;

    margin: 70px auto 35px;
    padding: 11px 0;

    background-color: #DB2727;
    border-radius: 5px;

    text-align: center;

    cursor: pointer;
}

.ended img, .techdiff img, .notFound img {
    width: 400px;
    margin-bottom: 30px;
    opacity: 0.95;
    margin-top: 16px;
    max-width: 82vw;
}

.notFound a {
    color: #DB2727;
    text-decoration: none;
}


@media only screen and (max-width: 1200px) {
    .streamView p {
        width: 70%;
    }
}

@media only screen and (max-width: 650px) {
    #sec {
        display: none;
    }

    .waiting ul {
        width: 378px;
    }
}

@media only screen and (max-width: 700px) {
    .streamView p {
        width: 80%;
    }
}

@media only screen and (max-width: 443px) {
    .waiting ul {
        width: 286px;
    }

    .waiting li {
        font-size: 18px;
        width: 93px;
    }

    .waiting li span {
        font-size: 32px;
    }
}

@media only screen and (max-height: 880px) {
    .streamView h1 {
        margin-top: 70px;
    }
}