a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block
}

body {
    line-height: 1
}

ol, ul {
    list-style: none
}

blockquote, q {
    quotes: none
}

blockquote:after, blockquote:before, q:after, q:before {
    content: '';
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}


:root {
    --bg-side: #101010;
    --bg-head: #141414;
    --bg-ctnt: #202020;
    --bg-cthl: #1D1D1D;
    --cl-htxt: #FFFFFF;
    --cl-rtxt: #E2E2E2;
    --cl-mtxt: #A4A4A4;
}

body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;

    color: var(--cl-rtxt);
    font-family: "Museo-Sans", sans-serif;
    text-align: left;
}


.preventCollapse {
    padding-top: 1px;
    margin-top: -1px;
}


::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--bg-ctnt);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #292929;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #333333;
}